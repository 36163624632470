<template>
  <div>
    <div>
      <span class="margin-r-sm">
        <el-date-picker
          v-model="deriveDate"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
          range-separator="至"
          size="mini"
          start-placeholder="开始日期"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </span>
      <el-select
        slot="prepend"
        v-model="searchType"
        placeholder="选择类型"
        size="mini"
      >
        <el-option :value="1" label="微信昵称"></el-option>
        <el-option :value="2" label="UID"></el-option>
      </el-select>
      <el-input
        v-model="studentName"
        class="margin-l-sm"
        clearable
        size="mini"
        style="width: 160px"
      ></el-input>

      <el-input
        v-model="sales_id"
        class="margin-l-sm"
        clearable
        placeholder="报单ID"
        size="mini"
        style="width: 160px"
      ></el-input>

      <el-button class="margin-l-sm" size="mini" @click="search"
        >筛选
      </el-button>
      <el-button class="fl-r" size="mini" type="success" @click="renewal"
      >一键更新
      </el-button>
      <el-button class="fl-r" size="mini" @click="derivelist"
        >导出列表
      </el-button>
      <el-button
        class="fl-r"
        icon="el-icon-plus"
        size="mini"
        type="primary"
        @click="showAddStudent"
        >添加学员
      </el-button>
      <div class="clear"></div>
    </div>
    <div class="margin-t-sm">
      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
          @selection-change="selectionChange"
        >
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>

          <el-table-column label="头像" width="80">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.user_name_avatar_url"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="用户id">
            <template slot-scope="scope">
              <span>{{ scope.row.user_id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.user_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报单ID" property="sales_id" ></el-table-column>
          <el-table-column label="创建时间" property="created_at" ></el-table-column>

          <el-table-column label="更新时间" property="created_at" width="200">
            <template slot-scope="scope">
              <p>{{ scope.row.start_at }}</p>
              <p>{{ scope.row.end_at }}</p>
            </template>
          </el-table-column>
          <el-table-column label="作业数量" property="created_at" >
            <template slot-scope="scope">
              <span>{{
                scope.row.work_number == 0 ? "无限制" : scope.row.work_number
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建人" property="creator"></el-table-column>
          <!--                    <el-table-column-->
          <!--                            property="index"-->
          <!--                            label="已学课时"-->
          <!--                            width="120">-->
          <!--                        <template slot-scope="scope">-->
          <!--                            <span>{{scope.row.now_day}}</span>-->
          <!--                        </template>-->
          <!--                    </el-table-column>-->
          <el-table-column label="学习课时数" property="index">
            <template slot-scope="scope">
              <p>
                {{ scope.row.start_plan_day }} - {{ scope.row.end_plan_day }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="当前更新天数" property="index">
            <template slot-scope="scope">
              <span>{{ scope.row.now_day }}</span>
            </template>
          </el-table-column>
          <el-table-column label="截止时间" property="index" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.out_at }}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新周期" property="address" width="200">
            <template slot-scope="scope">
              {{ scope.row.update_day }}
            </template>
          </el-table-column>
          <el-table-column label="备注" property="address" width="200">
            <template slot-scope="scope">
              {{ scope.row.remark }}
            </template>
          </el-table-column>
          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <span>
                <!-- {{detail}} -->
                <el-link type="primary" @click="punchIn(scope.row)"
                  >作业数量</el-link
                >
              </span>
              -
              <span>
                <el-link type="primary" @click="editStudent(scope.row)"
                  >编辑</el-link
                >
              </span>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r padding-b-sm">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20,50]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="punchInDialog"
      append-to-body
      title="设置作业数量"
      width="30%"
    >
      <el-input
        v-model="punchInData.work_number"
        placeholder="请输入内容"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="punchInDialog = false">取 消</el-button>
        <el-button
          :loading="submitBtnLoading"
          size="mini"
          type="primary"
          @click="setPunchIn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="showAddStudentDialog"
      append-to-body
      title="添加学员"
      width="50%"
    >
      <div>
        <div v-if="this.form.id === 0" class="padding-sm">
          <el-select
            slot="prepend"
            v-model="searchType"
            placeholder="选择类型"
            size="mini"
            @change="changeSearch"
          >
            <el-option :value="1" label="微信昵称"></el-option>
            <el-option :value="2" label="UID"></el-option>
          </el-select>

          <el-select
            ref="select"
            v-model="studentName"
            :loading="loading"
            :remote-method="searchStudent"
            class="margin-l-sm"
            clearable
            filterable
            placeholder="请输入关键词"
            remote
            reserve-keyword
            size="mini"
            @change="selectUser"
            @clear="clearUser"
            @hook:mounted="cancalReadOnly"
            @visible-change="cancalReadOnly"
          >
            <el-option
              v-for="item in users"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              style="height: 40px; line-height: 40px"
            >
              <div
                style="
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  height: 40px;
                  line-height: 40px;
                "
              >
                <span style="margin-right: 10px"> {{ item.id }} </span>
                <el-avatar :src="item.avatar_url" size="small"></el-avatar>
                <span style="margin-left: 10px">{{ item.name }}</span>
              </div>
            </el-option>
          </el-select>
        </div>

        <div v-if="notData" class="margin-t-sm">暂无数据</div>
        <div>
          <div style="margin-top: 12px">
            <div
              v-if="JSON.stringify(selectCurrentUser) !== '{}'"
              style="
                margin-top: 12px;
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 4px;
                margin: 4px;
              "
            >
              <el-avatar
                :src="selectCurrentUser.avatar_url"
                size="small"
              ></el-avatar>
              <span style="margin-left: 4px">{{ selectCurrentUser.name }}</span>
            </div>

            <el-form ref="form" :model="form" :rules="rules" label-width="auto">
              <el-form-item label="开始时间: " prop="start_at">
                <el-date-picker
                  v-model="form.start_at"
                  value-format="yyyy-MM-dd hh:mm:ss"
                />
              </el-form-item>

              <el-form-item label="是否全部计划: " prop="is_all">
                <el-switch
                  v-model="form.is_all"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>

              <el-form-item label="报单Id: " prop="sales_id">
                <el-input v-model="form.sales_id" />
              </el-form-item>

              <template v-if="form.is_all === 0">
                <el-form-item label="开始计划天数: " prop="start_plan_day">
                  <el-input v-model.number="form.start_plan_day" />
                </el-form-item>

                <el-form-item label="结束计划天数: " prop="end_plan_day">
                  <el-input v-model.number="form.end_plan_day" />
                </el-form-item>
              </template>

              <el-form-item label="更新周期: " prop="update_day">
                <el-checkbox-group v-model="form.update_day" size="small">
                  <el-checkbox-button
                    v-for="(city, index) in [
                      '日',
                      '一',
                      '二',
                      '三',
                      '四',
                      '五',
                      '六',
                    ]"
                    :key="city"
                    :label="index"
                  >
                    周{{ city }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="备注: ">
                <el-input
                  v-model="form.remark"
                  :rows="6"
                  clearable
                  maxlength="500"
                  placeholder="请输入内容"
                  resize="none"
                  show-word-limit
                  type="textarea"
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="hideAddStudent">取 消</el-button>
        <el-button
          :loading="submitBtnLoading"
          size="mini"
          type="primary"
          @click="addStudent"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
        append-to-body
        :show-close="false"
        :visible.sync="showUpdate"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="200px"
    >
      <div style="display: flex;justify-content: center">
        <el-progress type="circle" :percentage="((updateSuccess/updateData.length)*100)"></el-progress>
      </div>
      <div style="margin-top: 20px">更新中...</div>
    </el-dialog>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import tools from "../../../utils/tools";

export default {
  props: ["classTotal", "courseName"],
  name: "student",
  data() {
    return {
      showUpdate:false,
      submitBtnLoading: false,
      searchStudentBtn: false,
      dialogVisible: false,
      studentName: "",
      deriveDate: [],
      searchType: 1,
      showAddStudentDialog: false,
      deriveName: "",
      deriveType: 1,
      searchName: "",
      total: 0,
      loading: true,
      page: 1,
      sales_id: "",
      pageSize: 10,
      name: "",
      tableData: [],
      id: 0,
      searchStudents: [],
      notData: false,
      selectStudent: [],
      setLoading: false,
      workCountLoading: false,
      workCount: 0,
      users: [],
      selectCurrentUser: {},
      punchInDialog: false,
      punchInData: {},
      updateData:[],
      updateSuccess:0,
      form: {
        id: 0,
        start_at: "",
        is_all: 1,
        check_course_id: 0,
        user_id: 0,
        start_plan_day: 0,
        end_plan_day: 0,
        update_day: [1,  5],
        remark: "",
        sales_id: "",
      },
      rules: {
        start_at: [{ required: true }],
        is_all: [{ required: true }],
        user_id: [{ required: true }],
        start_plan_day: [{ required: true }],
        update_day: [{ required: true }],
        end_plan_day: [{ required: true }],
        sales_id: [{ required: true }],
      },
    };
  },
  watch: {
    classTotal: function (val) {},
    courseName: function (val) {
      console.log(val);
    },
  },
  methods: {
    ...mapActions("punch", [
      "getCheckStudent",
      "addCheckStudent",
      "WorkNumber",
    ]),
    ...mapActions("quiz", ["exportUsers"]),
    ...mapActions("user", ["getUserList"]),
    ...mapActions("common", ["delete"]),
    selectionChange(e){
      this.updateData = e
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
        }
      });
    },
    search() {
      this.page = 1;
      this.getCourseStudentList();
    },
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
        }
      });
      this.form.user_id = id;
      console.log(this.form);
    },
    clearUser() {},
    searchStudent(v) {
      console.log("searchStudent");
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
        searchData.pageSize = 1000;
      } else {
        searchData.uid = v;
        searchData.pageSize = 1000;
      }
      searchData.date_pick = this.deriveDate;

      if (v === "") {
        this.$message.error("请输入昵称");
        return;
      }

      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    changeSearch(e) {
      console.log("=================");
      console.log(e);
    },
    async renewal(){
      if(this.updateData.length == 0){
        return this.$message.error('请先选择需要更新的学员');
      }

      this.showUpdate = true

      for (let i=0;i<this.updateData.length;i++){

        let data = {
          "id":this.updateData[i].id,
          "start_at":this.updateData[i].start_at,
          "is_all":this.updateData[i].is_all,
          "check_course_id":this.updateData[i].check_course_id,
          "user_id":this.updateData[i].user_id,
          "start_plan_day":this.updateData[i].start_plan_day,
          "end_plan_day":this.updateData[i].end_plan_day,
          "update_day":this.updateData[i].update_day,
          "sales_id":this.updateData[i].sales_id,
          "remark":this.updateData[i].remark
        }
         let res = await this.addCheckStudent(data)
        if(res.ret == 0){
          this.updateSuccess = this.updateSuccess+1
        }
      }

      //
      if(this.updateSuccess == this.updateData.length){
        this.showUpdate = false

        this.$message.success('更新完成')
        setTimeout(()=>{
          this.updateSuccess = 0;
          console.log(111)
        },600)

        this.getCourseStudentList()
      }

      console.log(this.updateData)
    },
    async derivelist() {
      let form = {
        check_course_id: this.id,
        date_pick: this.deriveDate,
        sales_id: this.sales_id,
      };
      console.log(form);
      if ((this.searchType === 1) == 1) form.user_name = this.studentName;
      else form.user_id = this.studentName;

      const { data } = await this.exportUsers(form);
      if (!data) {
        return this.$message.error("筛选导出的数据为空！");
      }
      this.$message.success("添加到队列中");
      // var blob =
      //   "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      //   data;
      // var fileName = "学员" + tools.getFormatDate() + ".xlsx";
      // tools.downloadFileByBase64(blob, fileName);
    },
    showAddStudent() {
      this.form.id = 0;
      this.form.start_at = "";
      this.form.is_all = 1;
      this.form.user_id = 0;
      this.form.start_plan_day = 0;
      this.form.end_plan_day = 0;
      this.form.update_day = [1,  5];
      this.selectCurrentUser = {};
      this.showAddStudentDialog = true;
    },
    hideAddStudent() {
      this.showAddStudentDialog = false;
    },
    subStudent(student) {
      console.log(student);
      this.selectStudent.splice(this.selectStudent.indexOf(student), 1);
    },
    addStudent() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitBtnLoading = true;
          console.log(this.form);
          this.addCheckStudent(this.form)
            .then((res) => {
              if (res.ret === 0) {
                this.$message.success("操作成功");
                this.studentName = "";
                this.hideAddStudent();
                this.getCourseStudentList();
              }
            })
            .finally(() => {
              this.submitBtnLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    punchIn(item) {
      this.punchInDialog = true;
      this.punchInData = item;
    },
    setPunchIn() {
      this.submitBtnLoading = true;
      let punchInData = JSON.parse(JSON.stringify(this.punchInData));
      if (punchInData.work_number < 0) {
        this.$message.error("错了哦，作业数量不能少于1次，0等于无限制");
        return;
      }

      let form = {
        id: punchInData.id,
        workNumber: punchInData.work_number,
      };
      console.log(form);
      this.WorkNumber(form).then((res) => {
        if (res.res_info == "ok") {
          this.punchInDialog = false;
          this.$message.success("设置成功");
        }
        this.submitBtnLoading = false;
      });
    },
    editStudent(item) {
      this.showAddStudent();
      this.form.id = item.id;
      this.form.start_at = item.start_at;
      this.form.is_all = item.is_all;
      this.form.check_course_id = item.check_course_id;
      this.form.user_id = item.user_id;
      this.form.start_plan_day = item.start_plan_day;
      this.form.end_plan_day = item.end_plan_day;
      this.form.update_day = item.update_day;
      this.form.remark = item.remark;
      this.form.sales_id = JSON.parse(item.sales_id);
      this.selectCurrentUser.name = item.user_name;
      this.selectCurrentUser.id = item.user_id;
      this.selectCurrentUser.avatar_url = item.user_name_avatar_url;
      console.log(item);
    },
    getCourseStudentList() {
      this.loading = true;
      let data = {
        check_course_id: this.id,
        date_pick: this.deriveDate,
        sales_id: this.sales_id,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.searchType === 1 && this.studentName !== "") {
        data.user_name = this.studentName + "";
      }
      if (this.searchType === 2 && this.studentName !== "") {
        data.user_id = this.studentName;
      }
      this.getCheckStudent(data).then((res) => {
        if (res.ret == 0) {
          this.total = res.data.total;
          this.tableData = res.data.list;
          this.loading = false;
        }
      });
    },
    percentage(p) {
      return tools.percentage(p);
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getCourseStudentList();
    },
    setPage(page) {
      this.page = page;
      this.getCourseStudentList();
    },
    setStatus(row, rowIndex) {
      let _this = this;
      let data = {
        id: row.id,
        status: !row.status,
      };

      this.loading = true;
      this.setCourseUserStatus(data).then(
        (success) => {
          _this.tableData[rowIndex].status = success.data.status;

          _this.$message({
            message: "设置成功",
            type: "success",
          });
          this.loading = false;
        },
        (error) => {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
          this.loading = false;
        }
      );
    },
    deleted(row, rowIndex) {
      let _this = this;
      this.loading = true;
      this.delete({ id: row.id, model: "UserCheckCourse" }).then((res) => {
        this.getCourseStudentList();
        if (res.ret == 0) {
          this.$message.success("删除成功");
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.form.check_course_id = this.id;
    this.getCourseStudentList();
  },
};
</script>

<style>
.icon-hover {
  cursor: pointer;
  font-size: 14px;
}

.icon-hover:hover:before {
  font-size: 14px;
  content: "\e7a0";
}

.el-tag {
  height: auto;
  line-height: auto;
}

.el-tag .el-icon-close {
  top: 5px;
}

.derive {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}
</style>